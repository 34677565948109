<div style="padding: 0" clickOutside (clickOutSide)="handleClickOutSide($event)">
  <label *ngIf="showLabel" class="required" for="clinicId">Clinics</label> {{name}}
  <div class="input-select_container-opt-out">
    <input [value]="name" placeholder="Type to search Clinics" autocomplete="off"
           (keyup)="searchItem($event)" id="clinicId" class="input-select_field">
    <span class="input-select_caret pointer" (click)="toggleItems()"></span>
  </div>
  <div class="input-select_menu" [ngClass]="dropdownClass" *ngIf="isSearching || toggleSelect" >
    <div class="input-select_item" *ngIf="(data?.length < 1) && !isLoading">No Clinics Found!</div>
    <div class="input-select_item" *ngIf="isLoading">Search for Clinics...</div>
    <div class="input-select_item" (click)="getSelected(item)" *ngFor="let item of data">{{item.name}}</div>
  </div>
</div>
