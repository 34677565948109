import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerThankYouComponent } from './partner-thank-you/partner-thank-you.component';
import { SignupFormStep1Component } from './signup-form-step1/signup-form-step1.component';
import { SignupFormStep2Component } from './signup-form-step2/signup-form-step2.component';
import { SignupFormStep3Component } from './signup-form-step3/signup-form-step3.component';
import { SignupFormStep4Component } from './signup-form-step4/signup-form-step4.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { WhyPartnerComponent } from './why-partner/why-partner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CorePipesModule } from '@lib/shared-core/pipes/core-pipes.module';
import { SharedPrimeNgModule } from '@lib/shared-core/shared-prime-ng.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedDirectivesModule } from '@lib/shared-directives/shared-directives.module';
import { OptOutFormComponent } from './opt-out-form/opt-out-form.component';
import { OptOutThankYouComponent } from './opt-out-thank-you/opt-out-thank-you.component';
import { MdSelectComponent } from '@lib/shared-core/modules/components/md-select/md-select.component';

export const PartnerShipsComponents = [
  SignupFormStep1Component,
  SignupFormStep2Component,
  SignupFormStep3Component,
  SignupFormStep4Component,
  WhyPartnerComponent,
  SignupFormComponent,
  PartnerThankYouComponent,
  OptOutFormComponent,
  OptOutThankYouComponent,
  MdSelectComponent
];

@NgModule({
  declarations: [
    ...PartnerShipsComponents,
  ],
  imports: [
    SharedPrimeNgModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CorePipesModule,
    NgxIntlTelInputModule,
    SharedDirectivesModule
  ],
  exports: [
    ...PartnerShipsComponents,
  ]
})
export class PartnershipFormsModule { }
