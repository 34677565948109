import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-md-select',
  templateUrl: './md-select.component.html',
  styleUrls: ['./md-select.component.scss']
})
export class MdSelectComponent {

  @Input() data: Array<MDSelectItem> = [];

  @Input() name!: string;

  @Input()
  placeholder = 'Type to search Clinics';

  @Input() isSearching!: boolean;

  @Input() isLoading!: boolean;

  @Input() showLabel = true;

  @Input() dropdownClass = '';

  @Output()
  search: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  selected: EventEmitter<MDSelectItem> = new EventEmitter<MDSelectItem>();

  toggleSelect!: boolean;

  constructor() { }

  searchItem($event: Event) {
    this.search.emit($event);
    this.isSearching = true;
  }

  getSelected(data: MDSelectItem) {
    this.selected.emit(data);
    this.resetState();
  }

  handleClickOutSide($event: boolean) {
    this.toggleSelect = !$event;
    if (this.isSearching) {
      this.isSearching = false;
    }
  }

  toggleItems() {
    this.toggleSelect = !this.toggleSelect;
  }

  resetState() {
    this.toggleSelect = false;
    this.isSearching = false;
  }

}

export interface MDSelectItem {
  name: string;
  id: number;
}
