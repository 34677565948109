import { Component, EventEmitter, Input, isDevMode, Output } from '@angular/core';
import { PARTNERSHIP_FORM_ENUM } from '@lib/shared-core/components/partner-signup-core/partner-signup-core.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IpService } from '@lib/features/geolocation/ip.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IIpInfo } from '@lib/features/geolocation/ip.models';
import { RecaptchaActions } from '@lib/features/recaptcha/recaptcha.models';
import { RecaptchaService } from '@lib/features/recaptcha/recaptcha.service';
import { MessageService } from 'primeng/api';
import { debounceTime, finalize } from 'rxjs/operators';
import { PartnerService } from '../../../../../../../medical/src/app/modules/partner/partner.service';
import { MDSelectItem } from '@lib/shared-core/modules/components/md-select/md-select.component';
import { Subject } from 'rxjs';
import { ClinicService } from '@lib/modules/clinic/clinic.service';
import { IClinicFilter } from '@lib/modules/clinic/clinic.models';

@UntilDestroy()
@Component({
  selector: 'app-opt-out-form',
  templateUrl: './opt-out-form.component.html',
  styleUrls: ['../../../components/partner-signup-core/partner-signup-core.component.scss',
    './opt-out-form.component.scss']
})
export class OptOutFormComponent {
  @Input() siteName!: string;
  @Output() partnerFormState: EventEmitter<PARTNERSHIP_FORM_ENUM> = new EventEmitter();

  form: FormGroup;
  separateDialCode = false;
  selectedCountryISO: CountryISO = CountryISO.UnitedStates;
  preferredCountries: Array<string> = [
    CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.Canada, CountryISO.Australia, CountryISO.France
  ];
  readonly SearchCountryField = SearchCountryField;
  readonly PhoneNumberFormat = PhoneNumberFormat;

  private defaultValue = {
    clinic_name: 'Smart Medicals',
    why_opt_out: 'why opt out right here',
    how_can_we_improve: 'how can we improve right here',
    clinic_email: 'smartdental@doe.com',
    clinic_phone: '18040123'
  };
  processing!: boolean;

  isLoading = false;
  clinicName = '';
  isSearching = false;
  mdSelectData: Array<MDSelectItem> = [];
  private clinicFilter: IClinicFilter;

  private clinicsFilterSub$: Subject<string> = new Subject();


  constructor(
    private fb: FormBuilder,
    private ipService: IpService,
    private recaptchaService: RecaptchaService,
    private partnerService: PartnerService,
    private messageService: MessageService,
    private clinicService: ClinicService,
  ) {

    if (!isDevMode()) {
      this.defaultValue = {
        clinic_name: '',
        why_opt_out: '',
        how_can_we_improve: '',
        clinic_email: '',
        clinic_phone: ''
      };
    }

    this.clinicFilter = {
      filter: {
        name: '',
      },
      sort: 'name',
      page: {
        page: 1,
        size: 500
      }
    };

    this.clinicsFilterSub$.pipe(
      debounceTime(1000),
    ).subscribe(value => {
      if (this.clinicFilter.filter) {
        this.clinicFilter.filter.name = value;
      }
      this.isSearching = true;
      this.mdSelectData = [];
      this.fetchClinics();
    });

    this.form = this.fb.group({
      clinic_id: ['', Validators.required],
      clinic_name: [this.defaultValue.clinic_name, [Validators.required,
        Validators.minLength(2), Validators.maxLength(200)]],
      clinic_email: [this.defaultValue.clinic_email, [Validators.required, Validators.email]],
      clinic_phone: [this.defaultValue.clinic_phone, Validators.required],

      why_opt_out: [this.defaultValue.why_opt_out, [Validators.required,
        Validators.minLength(5), Validators.maxLength(300)]],

      how_can_we_improve: [this.defaultValue.how_can_we_improve, [Validators.required,
        Validators.minLength(5), Validators.maxLength(300)]],
    });

    this.ipService.ipGeoInfo$.pipe(untilDestroyed(this)).subscribe((data: IIpInfo | null) => {
      if (data !== null) {
        this.selectedCountryISO = data?.countryCode ? data?.countryCode.toLocaleLowerCase() as any : this.selectedCountryISO;
      }
    });
  }

  fetchClinics() {
    this.isLoading = true;

    this.clinicService.list(this.clinicFilter)
      .then((res: any) => {
        this.mdSelectData = res.data.map(
          (clinic: any) => ({id: clinic.id, name: clinic.name})) ?? [];
        this.clinicFilter = res.meta.filter;
        this.form.patchValue({clinic_id: '', clinic_name: ''});
        this.isLoading = false;
      }).catch((e: any) => {
      this.isLoading = false;
    });
  }

  submit() {
    this.form.markAllAsTouched();

    if (!this.form.valid) {
      return;
    }
    this.processing = true;
    this.form.value.clinic_phone = this.form.value.clinic_phone?.e164Number


    this.recaptchaService.generateToken(RecaptchaActions.PartnerOptOut)
      .pipe(untilDestroyed(this))
      .subscribe((token: string) => {
        (this.form.value as any)['g-recaptcha-response'] = token;
        this.partnerService.optOut(this.form.value ).pipe(
          finalize(() => {
            this.processing = false;
          })
        )
          .subscribe(
            res => {
              this.partnerFormState.emit(PARTNERSHIP_FORM_ENUM.OPT_OUT_THANK_YOU)
            },
            err => {
              alert('Opt Out was Unsuccessful');
            }
          );
      });

  }

  searchClinics($event: any) {
    this.clinicsFilterSub$.next($event.target.value);
  }

  getClinic($event: MDSelectItem) {
    if ($event && $event.id) {
      this.isSearching = false;
      this.clinicName = $event.name;
      this.form.patchValue({clinic_id: $event.id, clinic_name: $event.name});
    } else {
      this.form.patchValue({clinic_id: '', clinic_name: ''});
    }
  }
}
