import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[clickOutside]',
})
export class ClickOutsideDirective {
  @Output()
  clickOutSide: EventEmitter<boolean> = new EventEmitter<boolean>();



  constructor(private elementRef: ElementRef) {

  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {

    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.clickOutSide.emit(true);
    }
  }

}
