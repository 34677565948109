import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ICommon } from '../common/common.models';
import { LogService } from '../features/log/log.service';
import { ClinicAppointment, IClinicAppointmentDetail } from '../modules/appointment/appointment.models';
import { IPartnerOptOut, IPartnerSignUp } from '@lib/models/partner.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected staglCoreApiHost: string;
  protected medidApiHost: string;
  protected coreApiHost: string;
  private siteId: number;
  private googleConversionAPI: { partnerId: number; url: string } = {} as any;

  constructor(
    @Inject('env') private env: any,
    private http: HttpClient,
    private handler: HttpBackend,
    private logService: LogService) {
    this.logService.log('API.init()');
    this.siteId = this.env.site_id;
    if (!env) {
      this.logService.error('Missing env injecttoken');
    }

    this.staglCoreApiHost = env.api.staglCore;
    this.medidApiHost = env.api.medid;
    this.coreApiHost = env.api.core;
    this.googleConversionAPI = env.google_conversion_tracker;
  }

  private GET(apiFunction: string): Observable<any> {
    const resource = `${this.staglCoreApiHost}${apiFunction}`;
    // resource = resource.replace('//', '/');
    this.logService.log(`GET ${resource}`);
    return this.http.get<any>(resource).pipe(
      // retry(3),
      catchError(this.handleErrorGET)
    );
  }

  private POST(apiFunction: string, model: any): Observable<any> {
    this.logService.log(`POST ${this.coreApiHost}${apiFunction} -> ${JSON.stringify(model)}`);
    return this.http
      .post<any>(`${this.coreApiHost}${apiFunction}`, model)
      .pipe(catchError(this.handleError));
  }

  private PUT(apiFunction: string, model: any): Observable<any> {
    this.logService.log(`PUT ${this.staglCoreApiHost}${apiFunction} -> ${JSON.stringify(model)}`);
    return this.http
      .put<any>(`${this.coreApiHost}${apiFunction}`, model)
      .pipe(catchError(this.handleError));
  }

  private PATCH(apiFunction: string, model: any) {
    this.logService.log(`PATCH ${this.staglCoreApiHost}${apiFunction} -> ${JSON.stringify(model)}`);
    return this.http
      .patch<any>(`${this.coreApiHost}${apiFunction}`, model)
      .pipe(catchError(this.handleError));
  }

  private DELETE(apiFunction: string, id: string) {
    this.logService.log(`DELETE ${this.staglCoreApiHost}${apiFunction} -> ${id}`);
    return this.http
      .delete<any>(`${this.coreApiHost}${apiFunction}/${id}`)
      .pipe(catchError(this.handleError));
  }

  private handleErrorGET(error: HttpErrorResponse) {
    // For some reason, this code doesn't work. Need to check it out.
    // this.logService.log(`API Error: ${error.status}`);
    if (isDevMode()) {
      console.warn(`API POST Error: ${error.status}. LogService doesn't work as well.`);
    }
    return throwError(error);
  }

  private handleError(error: HttpErrorResponse) {
    if (isDevMode()) {
      console.error('handleError', error);
    }

    // if (error?.error instanceof ErrorEvent) {
    //   // A client-side or network error occurred. Handle it accordingly.
    //   this.logService.log(`API POST Error: ${error.message}`);
    // } else {
    //   this.logService.log(`API POST Error: ${error.status}, BODY: ${error}`);
    // }

    return throwError(error);
  }

  geolocationGET(hasTime?: boolean): Observable<any> {
    let url = `${this.staglCoreApiHost}geolocation`;
    if (hasTime) {
      const timestamp = Date.now(); // Generate a random timestamp
      url = `${url}?t=${timestamp}`;
    }
    return this.http.get(url);

  }

  commonGET(): Observable<ICommon> {
    this.logService.warn('API:commonGET() - Not using GET()');
    return this.http.get<ICommon>(`${this.staglCoreApiHost}common`);
  }

  homeGET(): Observable<any> {
    this.logService.log('API:homeGET()');
    return this.GET('home');
  }

  // Contact (site wide)

  contactPOST(model: any): Observable<any> {
    return this.http.post<any>(`${this.coreApiHost}contact`, model);
  }

  freeQuotePOST(model: any): Observable<any> {
    return this.http.post<any>(`${this.coreApiHost}quote`, model);
  }

  // Promotions

  promotionsGET(
    country?: string,
    province?: string,
    city?: string,
    clinic?: string,
    procedure?: string,
    pageNumber?: number
  ): Observable<any> {
    const params = new HttpParams()
      .set('page[number]', pageNumber!.toString())
      .set('country', country !== undefined ? country : '')
      .set('province', province !== undefined ? province : '')
      .set('city', city !== undefined ? city : '')
      .set('clinic', clinic !== undefined ? clinic : '')
      .set('procedure', procedure !== undefined ? procedure : '');

    const resource = `promotions?${params.toString()}`;
    return this.GET(resource);
  }

  promotionLatestGET(): Observable<any> {
    this.logService.log('API:promotionLatestGET()');
    return this.GET(`promotions/latest`);
  }

  promotionGET(promoCode: string): Observable<any> {
    this.logService.log(`API:promotionGET()`);

    if (promoCode === '') {
      throw new Error('promoCode cannot be empty');
    }

    return this.GET(`promotions/${promoCode}`);
  }

  // Premium content

  premiumContentOldGET(premiumId: string): Observable<any> {
    this.logService.log(`API:premiumContentOldGET()`);
    this.logService.warn('API:premiumContentOldGET() [OBSOLETED]');

    if (premiumId === '') {
      throw new Error('premiumId cannot be empty');
    }

    return this.GET(`premium-content/legacy?id=${premiumId}`);
  }

  premiumContentGET(treatment: string, country: string, province: string, city: string) {
    this.logService.log(`API:premiumContentGET()`);
    return this.GET(`premium-content?treatment=${treatment}&country=${country}&province=${province}&city=${city}`);
  }

  premiumContentSitemapGET(): Observable<any> {
    this.logService.log('API:premiumContentSitemapGET()');
    return this.GET('premium-content/sitemap');
  }

  // Affiliate

  affiliatesSignUp = (model: any) => this.POST(`affiliates`, model);

  // Article

  articlesRecentGET = () => this.GET(`articles/recent`);
  articlesRecommendedGET = () => this.GET(`articles/recommended`);

  articlesGET(categoryId: string, page?: number): Observable<any> {
    if (categoryId === '') {
      throw new Error('categoryId cannot be empty');
      // return this.GET(`articles/categories`);
    } else {
      if (page) {
        return this.GET(`articles/${categoryId}?page[number]=${page}`);
      } else {
        return this.GET(`articles/${categoryId}`);
      }
    }
  }

  articlesPageLinkGET = (pageLink: string) => this.http.get(pageLink);

  articlesPopularGET(categoryId: string): Observable<any> {
    if (categoryId === '') throw new Error('categoryId cannot be empty');
    return this.GET(`articles/${categoryId}/popular`);
  }

  articlesSitemapGET = (): Observable<any> => this.GET('articles/sitemap');

  articleGET(articleId: string): Observable<any> {
    if (articleId === '') throw new Error('articleId cannot be empty');
    return this.GET(`article/${articleId}`);
  }


  // CTA

  ctaGET(page?: number, size?: number,): Observable<any> {
    const params = `sort=-updated_at&page[page]=${page ?? 1}&page[size]=${size ?? 50}`;
    return this.GET(`cta?${params}`);
  }

  // Clinic

  clinicsTopAllGET = (): Observable<any> => this.GET('clinics/top/all');

  clinicsTopGET(
    treatmentId?: number,
    locationId?: number,
    size: number = 10
  ): Observable<any> {
    const location = locationId && locationId !== 0 ? locationId.toString().trim() : '';
    const treatment = treatmentId && treatmentId !== 0 ? treatmentId.toString().trim() : '';
    return this.GET(`clinics/top?location=${location}&treatment=${treatment}&size=${size}`);
  }

  clinicListGET(filterQueryString: string) {
    filterQueryString = this.setDefaultFilterQueryStringIfEmpty(filterQueryString);
    return this.http.get(`${this.staglCoreApiHost}clinics/search?${filterQueryString}`);
  }

  clinicsSitemapGET = (): Observable<any> => this.GET('clinics/sitemap');
  clinicGET = (clinicId: string): Observable<any> => this.GET(`clinics/${clinicId}`);
  clinicPreviewGET = (clinicId: string): Observable<any> => this.GET(`clinics/${clinicId}/preview`);

  clinicContactPOST = (model: any): Observable<any> => this.POST(`clinics/${model.clinic_id}/contact`, model);
  clinicAppointmentPOST = (model: ClinicAppointment): Observable<any> => this.POST(`clinics/${model.clinic_id}/appointment`, model);

  clinicAppointmentCancelPOST(model: IClinicAppointmentDetail): Observable<any> {
    return this.POST(`clinics/${model.clinic.id}/appointment/cancel`, {
      appointment_id: model.id,
      email: model.email,
      confirmation_code: model.confirmation_code,
      reason: model.cancel_reason
    });
  }

  clinicAppointmentConfirmPOST(model: any): Observable<any> {
    return this.POST(`appointment/confirm`, {
      appointmentTime: model.appointmentTime,
      clientInstructions: model.clientInstructions,
      providerInstructions: model.providerInstructions,
      confirmationCode: model.confirmationCode
    });
  }


  clinicReviewsGET = (clinicId: string, nextLink?: string): Observable<any> => {
    if (!nextLink) {
      return this.GET(`reviews/${clinicId}?page[number]=1`);
    } else {
      return this.http.get(nextLink);
    }
  }

  clinicImagesGET = (clinicId: string): Observable<any> => {
    return this.http.get<any>(`${this.coreApiHost}/clinics/${clinicId}/images`).pipe(
      catchError(this.handleErrorGET)
    );
  }

  clinicReviewUsefulnessPOST = (clinicId: number, model: any) => this.POST(`clinics/${clinicId}/reviews/usefulness`, model);
  clinicQuotePOST = (model: any): Observable<any> => this.POST(`clinics/${model.clinic_id}/quote`, model);

  clinicsCertificatesGET = (): Observable<any> => this.GET('clinics/certificates');
  clinicsSignUpPOST = (model: IPartnerSignUp): Observable<any> => this.POST('clinics/', model);
  clinicsOptOutPOST = (model: IPartnerOptOut): Observable<any> => this.POST('clinics/opt-out/', model);

  // Appointment

  appointmentDetailsPOST = (confirmationCode: string, email: string): Observable<any> => {
    return this.POST(`appointments/details`, {
      email: email,
      confirmation_code: confirmationCode
    });
  }

  incompleteAppointmentPUT = (model: any): Observable<any> => this.PUT(`appointment/incomplete`, model); // Create/Replace
  incompleteAppointmentPOST = (model: { clinic_id: string, ip: string }): Observable<any> =>
    this.POST(`appointment/incomplete`, model); // Ge
  incompleteAppointmentDELETE = (id: number): Observable<any> => this.DELETE(`appointment/incomplete`, id.toString());

  // Google Conversion Tracker
  googleConversionTrackerPOST = (model: IGoogleConversionTracker): Observable<any> => {
    model.conversion_partner_id = this.googleConversionAPI.partnerId;
    const headers = new HttpHeaders({'Content-Type': 'text/plain'});


    return this.http.post<any>(`${this.googleConversionAPI.url}`, model, {headers})
      .pipe(catchError(this.handleError));
  }

  // Reviews

  reviewImageUploadPOST(clinicId: number, images: any): Observable<any> {
    if (!clinicId) {
      throw new Error('Invalid Clinic ID: ' + clinicId + ', Images: ' + images);
    }

    const uploadData = new FormData();
    uploadData.append('clinic_id', clinicId.toString());
    // uploadData.append('files', images);
    for (let i = 0; i < images.length; i++) {
      uploadData.append(`file[${i}]`, images[i]);
      uploadData.append(`filename[${i}]`, images[i].name);
    }

    return this.http.post<any>(
      `${this.staglCoreApiHost}reviews/upload-photos`,
      uploadData,
      {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data'
        })
      }
    );
  }
  reviewImageUploadViaIDPOST(clinicId: number, images: any, reviewId: any): Observable<any> {

    if (!clinicId) {
      throw new Error('Invalid Clinic ID: ' + clinicId + ', Images: ' + images);
    }

    const newHttp = new HttpClient(this.handler);
    const uploadData = new FormData();
    let headers = new HttpHeaders({ 'X-MD-Site-Id': this.siteId.toString() });
    for (let i = 0; i < images.length; i++) {
      uploadData.append('files', images[i]);
    }

    return newHttp.post<any>(
      `${this.staglCoreApiHost}reviews/${reviewId}/upload-photo`,
      uploadData, { headers: headers }
    );
  }

  reviewImageRemovePOST(clinicId: number, imagePath: string): Observable<any> {
    if (!clinicId) {
      throw new Error('Invalid Clinic ID: ' + clinicId + ', Image Path: ' + imagePath);
    }

    const uploadData = new FormData();
    uploadData.append('clinic_id', clinicId.toString());
    uploadData.append('filename', imagePath);

    return this.http.post<any>(
      `${this.staglCoreApiHost}reviews/remove-photo`,
      uploadData
    );
  }

  reviewClinicPOST(model: any): Observable<any> {
    return this.POST(`reviews/clinic`, model);
    // return this.POST(`clinics/${model.clinic_id}/appointment`, model);
  }

  reviewsLatestGET(location: string = ''): Observable<any> {
    let _location = '';
    if (location !== '') {
      _location = `?location=${location.trim()}`;
    } else {
      _location = '';
    }

    this.logService.log(`API:reviewsLatestGET():reviews/latest${_location}`);
    return this.GET(`reviews/latest${_location}?count=10`);
  }

  reviewsByClinicGET(clinic: string): Observable<any> {
    return this.GET(`reviews/${clinic}`);
  }

  // Doctor

  doctorGET(doctorId: string): Observable<any> {
    if (doctorId === '') {
      throw new Error('doctorId cannot be empty');
    }

    return this.GET(`doctors/${doctorId}`);
  }

  // Location

  locationsGET() {
    this.logService.log(`API:locationsGET():/locations`);
    return this.GET(`locations`);
  }

  locationPopularGET(
    treatmentId?: number,
    locationId?: number
  ): Observable<any> {
    const treatment = treatmentId ? treatmentId.toString().toLowerCase().trim() : '';
    const location = locationId ? locationId.toString().toLowerCase().trim() : '';

    return this.GET(`locations/popular?treatment=${treatment}&location=${location}`);
  }

  locationCountriesGET(): Observable<any> {
    return this.GET('location/countries');
  }

  // Treatment

  treatmentsGET() {
    return this.GET(`treatments`);
  }

  treatmentPopularGET(locationId?: number): Observable<any> {
    const location = locationId ? locationId.toString().trim() : '';

    return this.GET(`treatments/popular?location=${location}`);
  }

  treatmentsOfficialProcedureGET(slug: string): Observable<any> {
    return this.GET(`treatments/official-procedure/${slug}`);
  }


  // GPC

  gpcGET(year: number): Observable<any> {
    return this.GET(`gpc/${year}`);
  }

  gpcCountryGET(year: number, country_slug: string): Observable<any> {
    return this.GET(`gpc/${year}/${country_slug}`);
  }

  gpcCountryCityGET(
    year: number,
    country_slug: string,
    city_slug: string
  ): Observable<any> {
    return this.GET(`gpc/${year}/${country_slug}/${city_slug}`);
  }

  // About Team
  teamGET = (): Observable<any> => this.GET(`team`);

  // Press

  pressGET(nextLink?: string): Observable<any> {
    if (!nextLink) {
      return this.GET(`press?page[number]=1`);
    } else {
      return this.http.get(nextLink);
    }
  }

  pressDetailGET(id: string): Observable<any> {
    return this.GET(`press/${id}`);
  }

  sponsoredAdsTracking(model: any, action: string): Observable<any> {
    return this.http.post<any>(`${this.coreApiHost}sponsored-clinics/${action}`, model);
  }

  // Redirect

  redirectCheckGET(deprecated_url: string, kind?: string): Observable<any> {
    return this.http.post<any>(
      `${this.staglCoreApiHost}check-redirect`, {
      deprecated_url
    });
    // return this.POST(`check-redirect`, {
    //   deprecated_url: deprecated_url
    // });
  }

  // Newsletter

  newsletterSubscribePOST = (model: any) => this.POST('subscribe-newsletter', model);
  newsletterUnsubscribePOST = (model: any) => this.POST('unsubscribe-newsletter', model);
  tokenDecryptPOST = (model: { data: string }) => this.POST('util/decrypt', model);

  // Call Back Request

  callBackRequestPOST = (model: any) => this.POST('call-back-request', model);

  // Survey

  implantQuestionnairePOST = (model: {
    clinic_id: number,
    user_ip: string,
    treatments: {
      treatment: string,
      brands: {
        brand: string,
        brandOther: string,
        price: number,
        currency: string
      }[]
    }[]
  }) => this.POST('survey/implant-questionnaire', model)

  private setDefaultFilterQueryStringIfEmpty(filterQueryString: string) {
    return (filterQueryString !== '') ? filterQueryString : `page[page]=1&page[size]=20&filter[siteId]=0&sort=-id`;
  }
}

export interface IGoogleConversionTracker {
  conversion_partner_id?: number,
  rwg_token: string,
  merchant_changed: number
}
